<template>
    <div v-show="tasks.length" class="card border-dark task-group mb-2 p-1"
         :class="{ 'is-not-auto-reason': isNotAutoProductionPlanReason }">
        <div style="padding: 10px 5px" v-b-toggle="`group-${groupKey}`">
            {{ nomenclatureName }}
            <b v-if="isNotAutoProductionPlanReason">&nbsp;({{ productionPlanReason.comment.toLowerCase() }})</b>

            <div style="display: flex; justify-content: space-between; margin: 10px 0">
                <span style="color: #000099;font-size: 12px;">{{ operationName }}</span>
                <span style="color: #336600;">{{ producedCount }} / {{ count }} {{ measureUnitName }}</span>
            </div>

            <div style="display: flex; align-items: center;">
                <b-icon-clock-fill :variant="clockVariant" class="mr-2" font-scale="2"/>

                <div v-for="task in tasks.slice(0, 3)" class="alert border border-dark p-0 mr-2 mb-0" :class="'state-' + task.state">
                    <div style="padding: 4px;display: inline-block;border-right: 1px solid;">
                        <div>
                            {{ moment(task.production_start_at).format('HH:mm') }}
                            –
                            {{ moment(task.production_end_at).format('HH:mm') }}
                        </div>
                        <div>
                            <b v-if="task.fromPreviousShift">с прошлой смены</b>
                            <b v-if="task.fromBeforeLastShift">с позапрошлой смены</b>
                        </div>
                    </div>
                    <div style="padding: 4px;display: inline-block;" :title="moment(task.plan.date).format('DD.MM.YYYY')">
                        {{ moment(task.plan.date).format('DD') }}
                    </div>
                </div>

                <div
                    v-if="tasks.length > 3"
                    class="alert border border-dark p-1 mb-0"
                >
                    ...
                </div>
            </div>
        </div>
        <b-collapse :id="`group-${groupKey}`">
            <div class="task-table table-responsive">
                <table class="table table-bordered b-table">
                    <tbody>
                    <tr v-for="task in tasks" :class="{'is-not-auto-reason': isNotAutoReason(task)}">
                        <td class="text-center" :class="`state-` + task.state"
                            style="vertical-align: middle; width: 1px;">
                            <template v-if="isTaskHasProductionActWithMultipleTasks(task)">
                                <b-icon-list-ul scale="2"/>
                            </template>
                        </td>
                        <td @dblclick="openProductionAct(task)" class="cursor-pointer">
                            <div class="row">
                                <div class="col-12">
                                    {{
                                        moment(task.production_start_at).format('HH:mm')
                                    }} - {{ moment(task.production_end_at).format('HH:mm') }}
                                    <b-badge
                                        v-if="task.fromPreviousShift"
                                        variant="success"
                                        class="mr-1"
                                        style="font-size:0.9em;"
                                    >
                                        с прошлой смены
                                    </b-badge>
                                    <b-badge
                                        v-if="task.fromBeforeLastShift"
                                        variant="danger"
                                        class="mr-1"
                                        style="font-size:0.9em;"
                                    >
                                        с позапрошлой смены
                                    </b-badge>
                                    <b-badge
                                        v-if="isNotAutoReason(task)"
                                        variant="info"
                                        style="font-size:0.9em;"
                                    >
                                        {{ task.plan.reason.comment.toLowerCase() }}
                                    </b-badge>
                                </div>
                            </div>
                            <div v-if="task.ancestor" class="row">
                                <div class="col-12">
                                    <span><strong>{{ task.ancestor.storage.name }}</strong></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <small>{{ task.plan.nomenclature.name }}</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <small class="text-primary">{{ moment(task.plan.date).format('DD.MM.YYYY HH:mm') }}</small>
                                </div>
                            </div>
                        </td>
                        <td class="text-right" v-on:dblclick="openProductionAct(task)">
                            <div>{{ task.count.toFixed(3) }}{{ measureUnitName }}</div>
                            <button class="btn btn-outline-dark btn-sm mt-2" @click="openTech(task)"
                                    v-show="tasks.length > 1">
                                Детали технологии
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button
                        v-show="tasks.length === 1 || productionPlanNomenclatureId === dishesNomenclatureId"
                        class="btn btn-outline-dark"
                        @click="openTech(tasks[0])"
                    >
                        Детали технологии
                    </button>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import {flatten, sumBy, uniqBy} from 'lodash';
import moment from 'moment'
import {REASON_AUTO_PRODUCTION_PLAN} from "@constants";

export default {
    props: {
        groupKey: {
            required: true,
            type: String
        },
        tasks: {
            required: true,
            type: Array
        },
        showFinished: {
            type: Boolean,
            default: false
        },
        dishesNomenclatureId: {},
        shiftClosingAct: {
            type: Object,
            required: false,
            default: null
        },
    },
    computed: {
        isNotAutoProductionPlanReason() {
            return this.productionPlanReason &&
                this.productionPlanReason.name !== REASON_AUTO_PRODUCTION_PLAN;
        },
        productionPlanNomenclatureId() {
            return this.tasks[0].plan.nomenclature.id;
        },
        productionPlanReason() {
            return this.tasks[0].plan.reason;
        },
        nomenclatureName() {
            return this.tasks[0].nomenclature.name;
        },
        operationName() {
            return this.tasks[0].operation.name
        },
        measureUnitName() {
            return this.tasks[0].nomenclature.measure_unit.name
        },
        count() {
            return this.tasks.reduce((accumulator, task) => {
                return accumulator + task.count
            }, 0).toFixed(3);
        },
        producedCount() {
            return sumBy(uniqBy(flatten(this.tasks.map(t => t.production_acts)), 'id'), pa => sumBy(pa.items, i => {
                if (this.isProductionActItemCountable(i)) {
                    return parseFloat(i.count);
                }

                return 0;
            })).toFixed(3)
        },
        isLate() {
            return this.tasks.some(t => t.isLate)
        },
        isSoon() {
            return this.tasks.some(t => t.isSoon)
        },
        clockVariant() {
            if (this.isLate) {
                return 'danger'
            }

            if (this.isSoon) {
                return 'success'
            }

            return null;
        },
    },
    methods: {
        moment,
        isNotAutoReason(task) {
            return task.plan.reason && task.plan.reason.name !== REASON_AUTO_PRODUCTION_PLAN;
        },
        openTech(task) {
            this.$emit('openTech', task)
        },
        openProductionAct(task) {
            this.$emit('openProductionAct', [task])
        },
        isTaskHasProductionActWithMultipleTasks(task) {
            if (!task.production_acts) {
                return false;
            }

            for (let i = 0; i < task.production_acts.length; i++) {
                if (!!task.production_acts[i].production_tasks && task.production_acts[i].production_tasks.length > 1) {
                    return true
                }
            }

            return false
        },
        isProductionActItemCountable(item) {
            if (!!this.shiftClosingAct && !!this.shiftClosingAct.blocked_at) {
                return moment(item.created_at).format('X') <= moment(this.shiftClosingAct.blocked_at).format('X');
            }

            return true;
        },
    }
}
</script>

<style scoped>
.is-not-auto-reason {
    background-color: #f8d7da;
}

.card.task-group {
    width: 100%;
}

.task-group table,
.task-group >>> table {
    margin-bottom: 0;
}

.task-group .state-started,
.task-group >>> .state-started {
    background: #ffe69c;
}

.task-group .state-finished,
.task-group >>> .state-finished {
    background: #a3cfbb;
}

.task-group .task-time {
    border: 1px solid black;
    border-radius: 5px;
    /*padding: 4px;*/
    font-size: 12px;
    white-space: nowrap;
    margin-right: 10px;
}

.task-group .task-table >>> thead {
    display: none !important;
}

>>> .task-group td {
    padding: .4rem;
}

>>> .print-labels-modal .modal-body,
>>> .task-details .modal-body {
    padding: .75rem 0.5rem;
}

>>> .print-labels-modal .modal-header,
>>> .task-details .modal-header {
    padding: .5rem;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
