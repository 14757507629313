<template>
    <div>
        <b-card no-body class="mb-2 p-1">
            <div class="row">
                <div class="col-md-5">
                    <router-link
                        class="btn btn-primary"
                        :to="{name: 'ProductionTaskList'}"
                    >
                        <b-icon-printer-fill/>
                        Для печати
                    </router-link>
                    <router-link class="btn btn-primary ml-2"
                                 :to="{name: 'ProductionTaskListPlans'}">
                        <b-icon-printer-fill/>
                        Печать по продукции
                    </router-link>
                </div>
                <div class="col-md-7 text-right">
                    <b-button variant="primary" @click="load">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                    <span class="dropdown-toggle" v-b-toggle.filters>
                        <span style="display: inline-block;margin: 0 10px;font-weight: 600;" v-if="filters.storage">
                            {{ filters.storage.name }}
                        </span>
                        <span style="display: inline-block;font-weight: 600;" v-if="filters.date || filters.shift">
                            ({{ filters.date ? moment(filters.date).format('DD.MM.YY') : '' }}<template
                            v-if="filters.date && filters.shift"> / </template>{{
                                filters.shift ? filters.shift.name : ''
                            }})
                        </span>
                    </span>
                </div>
            </div>

            <b-collapse id="filters" visible>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group description="План на дату">
                                <b-form-datepicker
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder=""
                                    v-model="filters.date"
                                    @input="load"
                                />
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group description="Цех">
                                <storage-picker
                                    v-model="filters.storage"
                                    :selectableForTaskList="true"
                                    :allowedOnly="true"
                                    @input="load"
                                />
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group description="Смена">
                                <shift-input v-model="filters.shift" @input="load"></shift-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-checkbox v-model="filters.showFinished">Показывать завершенные</b-checkbox>
                        </div>
                        <div class="col-md-4">
                            <b-checkbox v-model="filters.lateTop">Опаздывающие сверху</b-checkbox>
                        </div>
                        <div class="col-md-4">
                            <b-checkbox v-model="filters.groupByPlan">Группировать по продукции</b-checkbox>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </b-card>
        <div class="card-columns" style="column-count: 1; position: relative;">
            <template v-if="filters.groupByPlan">
                <div v-for="(plan, planId) in plans">
                    <div
                        class="bg-info"
                        style="font-weight: 600; font-size: 16px;color: black;padding: 10px;display: flex;justify-content: space-between;"
                    >
                        <span>
                            <router-link
                                :to="{name: 'ProductionTaskListPlans', params: {planId: plan.id}}"
                                class="btn btn-sm btn-primary mr-2"
                            >
                                <b-icon-printer-fill/>
                            </router-link>
                            <span>{{ plan.nomenclature }}</span>
                        </span>
                        <span>
                            {{ plan.count }}
                            {{ plan.measureUnit }}
                        </span>
                    </div>
                    <task-group
                        v-for="group in plan.groups"
                        :tasks="group.tasks"
                        :key="planId + '-' + group.key"
                        :group-key="planId + '-' + group.key"
                        :dishes-nomenclature-id="group.nomenclatureId"
                        @openTech="openTech"
                        @openProductionAct="openProductionAct"
                    />
                </div>
            </template>
            <template v-else>
                <div v-if="loading || shiftLoading" class="alert alert-secondary text-center mb-3">
                    <b-spinner/>
                </div>
                <div v-else-if="taskGroups.length === 0" class="alert alert-warning text-center">Нет заданий</div>
                <template v-else>
                    <task-group
                        v-for="group in taskGroups"
                        :tasks="group.tasks"
                        :key="group.key"
                        :group-key="group.key"
                        :dishes-nomenclature-id="group.nomenclatureId"
                        @openTech="openTech"
                        @openProductionAct="openProductionAct"
                        :shift-closing-act="shiftClosingAct"
                    />
                </template>
            </template>
        </div>

        <b-modal
            id="details"
            hide-footer
            centered
            scrollable
            size="lg"
            ref="techModal"
            title="Детали технологии"
            @hidden="selectedTask = null; showTaskImage = false"
        >
            <b-tabs v-if="selectedTask" pills justified>
                <b-tab title="Детали" active>
                    <table class="table table-bordered mt-2">
                        <tr v-for="stuff in selectedTask.stuff">
                            <td>{{ stuff.nomenclature.name }}</td>
                            <td class="text-right">
                                <template v-if="filters.storage.packing">
                                    {{ (stuff.count_tehn / selectedTask.count).toFixed(3) }}
                                </template>
                                <template v-else>
                                    {{ stuff.count_tehn.toFixed(3) }}
                                </template>
                                {{ stuff.nomenclature.measure_unit.name }}
                            </td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td>Итого</td>
                            <td class="text-right">
                                {{ totalCount(selectedTask.stuff) }} кг.
                            </td>
                        </tr>
                    </table>
                    <p class="text-justify">{{ selectedTask.description }}</p>
                </b-tab>
                <b-tab title="Фото" @click="showTaskImage = true">
                    <div class="text-center mt-2">
                        <b-spinner v-show="imageLoading"/>
                        <img v-show="!imageLoading" :src="taskImage" style="max-width: 100%;" alt="Ошибка загрузки">
                    </div>
                </b-tab>
            </b-tabs>
        </b-modal>
    </div>
</template>

<script>
const KILOS_KEY = 'kilos';

const STATE_NONE = 'not_started';
const STATE_STARTED = 'started';
const STATE_FINISHED = 'finished';

import {sortBy, groupBy, mapValues, sumBy} from 'lodash'
import moment from 'moment';
import StoragePicker from "@components/_common/StoragePicker";
import ControlPanel from "@components/_common/ControlPanel";
import ShiftInput from "@components/_common/ShiftInput";
import SimpleLoader from "@components/_common/SimpleLoader";
import TaskGroup from './TaskGroup';
import {
    PRODUCTION_TASKS_ENDPOINT,
    SHIFT_CLOSING_ACT_ENDPOINT
} from "@utils/endpoints";

export default {
    components: {
        TaskGroup,
        StoragePicker,
        ShiftInput,
        ControlPanel,
        SimpleLoader
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                // Не сохраняем showFinished
                let filters = structuredClone(this.filters);
                localStorage.setItem('production_task_dashboard_filter', JSON.stringify(filters));
            }
        },
        showTaskImage(val) {
            if (val) {
                this.imageLoading = true
                this.$store.dispatch('nomenclature/info/getNomenclatureInfo', this.selectedTask.plan.nomenclature.id)
                    .finally(() => this.imageLoading = false)
            }
        }
    },
    computed: {
        plans() {
            let tasks = this.tasks || [];

            if (!this.filters.showFinished) {
                tasks = tasks.filter(task => {
                    return !(task.state === STATE_FINISHED && this.isTransferredCompletely(task));
                });
            }

            let result = [];

            mapValues(groupBy(tasks, 'plan.id'), t => {
                result.push({
                    id: parseInt(t[0].plan.id),
                    nomenclature: t[0].plan.nomenclature.name,
                    measureUnit: t[0].plan.nomenclature.measure_unit.name,
                    count: t[0].plan.count,
                    groups: this.groupTasks(t),
                })
            });

            return result;
        },
        taskGroups() {
            let tasks = this.tasks || [];

            if (!this.filters.showFinished) {
                tasks = tasks.filter(t => {
                    return !(t.state === STATE_FINISHED && this.isTransferredCompletely(t))
                });
            }

            return this.groupTasks(tasks);
        },
        taskImage() {
            return this.$store.getters['nomenclature/info/image'](this.selectedTask.plan.nomenclature.id)
        }
    },
    data() {
        return {
            selectedTask: null,
            showTaskImage: false,
            imageLoading: false,
            filters: {
                showFinished: false,
                lateTop: false,
                storage: null,
                date: new Date,
                shift: null,
                groupByPlan: false
            },
            tasks: null,
            loading: false,
            shiftClosingAct: null,
            shiftLoading: false,
        };
    },
    methods: {
        moment,
        totalCount(stuffs) {
            let totalCount = 0;
            let filteredStuffs = stuffs.filter((stuff) => stuff.nomenclature.measure_unit.key === KILOS_KEY);

            totalCount = sumBy(filteredStuffs, 'count_tehn');
            if (this.filters.storage.packing) {
                totalCount /= this.selectedTask.count;
            }

            return (totalCount).toFixed(3);
        },
        openTech(task) {
            this.selectedTask = task;
            this.$refs.techModal.show();
        },
        load() {
            this.tasks = null;

            if (!this.filters.storage || !this.filters.shift || !this.filters.date) return;

            this.loadShiftClosingAct();
            this.$http.get(PRODUCTION_TASKS_ENDPOINT + '/dct_list', {
                params: {
                    date: moment(this.filters.date).format('YYYY-MM-DD'),
                    shift_id: this.filters.shift.id,
                    storage_id: this.filters.storage.id,
                    without_loading: true,
                },
                before(request) {
                    // abort previous request, if exists
                    if (this.previousRequest) {
                        this.previousRequest.abort();
                    }
                    // set previous request on Vue instance
                    this.previousRequest = request;
                    this.loading = true;
                }
            })
            .then(response => {
                this.tasks = response.data
            }).catch(response => {
                let message = 'Ошибка загрузки заданий'
                if (!!response.data && !!response.data.message) {
                    message = response.data.message;
                } else if (response.status !== 0) {
                    console.log(response);
                }

                this.$bvToast.toast(message, {variant: 'danger'});
            }).finally(() => {
                this.loading = false;
            });
        },
        groupTasks(tasks) {
            let groupedTasks = groupBy(tasks, t => {
                return `${t.nomenclature.id}-${t.operation.id}`
            });

            let groups = [];

            mapValues(groupedTasks, tasks => {
                groups.push({
                    nomenclatureId: tasks[0].nomenclature.id,
                    key: `${tasks[0].nomenclature.id}-${tasks[0].operation.id}`,
                    tasks
                })
            });

            let sortCallbacks = [
                group => group.tasks[0].production_start_at,
                group => group.tasks[0].production_end_at
            ];

            if (this.filters.lateTop) {
                sortCallbacks.unshift(group => !group.tasks.some(t => t.isLate));
            }

            return sortBy(groups, sortCallbacks)
        },
        openProductionAct(tasks) {
            if (tasks.length === 0) {
                return
            }

            let tasksWithProductionActs = tasks.filter(t => t.production_acts.length > 0)

            // Ищем акт внутри тасок
            if (tasksWithProductionActs.length) {
                let productionAct = tasksWithProductionActs[0].production_acts[0];
                // Открываем форму акта
                this.$router.push({
                    name: 'ProductionActUpdate',
                    params: {
                        id: productionAct.id
                    }
                })
            } else {
                this.$bvToast.toast('Интерфейс доступен только для просмотра. Для работы используйте Рабочий стол', {variant: 'danger'});
            }
        },
        isTransferredCompletely(task) {

            if (!task.ancestor || task.ancestor.storage.id === this.filters.storage.id) {
                return true;
            }

            if (!task.production_acts || !task.production_acts.length) {
                return false;
            }

            let transferredCount = 0;
            let producedCount = 0;
            task.production_acts.map(act => {
                if (act.items && act.items.length) {
                    producedCount += sumBy(act.items, 'count');
                }

                if (act.material_transfer_acts && act.material_transfer_acts.length) {
                    act.material_transfer_acts.map(transferAct => {
                        if (!transferAct.canceled) {
                            transferAct.items.map(item => {
                                if (item.nomenclatureLot.nomenclatureId === task.nomenclature.id) {
                                    transferredCount += item.count;
                                }
                            })
                        }
                    });
                }
            });

            return parseFloat(transferredCount.toFixed(3)) >= parseFloat(producedCount.toFixed(3));
        },
        toggleShowImage() {
            this.showTaskImage = !this.showTaskImage
        },
        loadShiftClosingAct() {
            this.$http.get(SHIFT_CLOSING_ACT_ENDPOINT + '/by-date-shift', {
                params: {
                    storage_id: this.filters.storage.id,
                    shift_id: this.filters.shift.id,
                    date: moment(this.filters.date).format('YYYY-MM-DD'),
                    without_loading: true,
                },
                before(request) {
                    // abort previous request, if exists
                    if (this.shiftPreviousRequest) {
                        this.shiftPreviousRequest.abort();
                    }
                    this.shiftLoading = true;
                    // set previous request on Vue instance
                    this.shiftPreviousRequest = request;
                }
            }).then(response => {
                this.shiftClosingAct = response.data;
            }).catch(response => {
                let message = 'Ошибка загрузки заданий'
                if (!!response.data && !!response.data.message) {
                    message = response.data.message;
                } else if (response.status !== 0) {
                    console.log(response);
                }

                this.$bvToast.toast(message, {variant: 'danger'});
            }).finally(() => {
                this.shiftLoading = false;
            });
        },
    },
    mounted() {
        let filtersJson = localStorage.getItem('production_task_dashboard_filter')

        if (filtersJson) {
            filtersJson = JSON.parse(filtersJson)
            this.filters = filtersJson
        }

        this.load()
    }
}
</script>
